import React, { FC, useCallback, useContext, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { AdvisorAccount, UserProfile } from '../../../common/types/database'
import { RecordWithID } from '../../../common/types/helpers'
import { WebsiteLeadsTableContext } from '../website-leads-table-context'
import { BeatLoader } from "react-spinners";
import { Colors } from '../../../common/colors/colors'
import { CaretDownFilled } from '@ant-design/icons'
import { Popover2 as Popover } from "@blueprintjs/popover2";
import { Alert, Menu, MenuItem, Position } from '@blueprintjs/core'
import { updateAdvisorAccount } from '../../../common/logic/advisor-account-logic'
import { ToasterContext } from '../../toaster-provider/toaster-context'

type AssignedToUserSelectorProps = {
    record: RecordWithID<AdvisorAccount>
}

export const AssignedToUserSelector: FC<AssignedToUserSelectorProps> = ({
    record,
}) => {

    // hooks
    const styles = useStyles()
    const { toaster } = useContext(ToasterContext)
    const { allUserProfiles } = useContext(WebsiteLeadsTableContext)
    const selectedUserProfile = useMemo(() => allUserProfiles?.find(userProfile => userProfile.id === record.AssignedToUserID), [record.AssignedToUserID, allUserProfiles])
    const display = useMemo(() => {
        if (!allUserProfiles) return <BeatLoader color={Colors.primary} size={12} speedMultiplier={0.75} />
        if (!selectedUserProfile) return 'Nobody yet'
        return `${selectedUserProfile.firstName} ${selectedUserProfile.lastName}`
    }, [selectedUserProfile, record.AssignedToUserID, allUserProfiles])
    const [shouldSetToAssignedToUserId, setShouldSetToAssignedUserId] = useState<string>()
    const alertIsOpen = useMemo(() => shouldSetToAssignedToUserId ? true : false, [shouldSetToAssignedToUserId])

    // event handlers
    const handleAssignedToUserChanged = useCallback((userProfile: RecordWithID<UserProfile>) => {
        if (userProfile.id !== record.AssignedToUserID) {
            setShouldSetToAssignedUserId(userProfile.id)
        }
    }, [record.id, record.AssignedToUserID])

    const handleAlertClose = useCallback(() => {
        setShouldSetToAssignedUserId(undefined)
    }, [])

    const handleAlertConfirm = useCallback(() => {
        setShouldSetToAssignedUserId(id => {
            if (id) updateAdvisorAccount(record.id, { AssignedToUserID: id })
            .then(() => {
                const userProfile = allUserProfiles?.find(profile => profile.id === id)
                toaster?.show({
                    message: <span><strong>{record.firstName}&nbsp;{record.lastName}</strong><span>&nbsp;now assigned to&nbsp;</span><strong>{userProfile?.firstName}&nbsp;{userProfile?.lastName}</strong></span>,
                    intent: 'success',
                })
            })
            .catch((err) => {
                toaster?.show({
                    message: `We ran into a problem updating that lead. If this happens again, please contact Charlie.`,
                    intent: 'danger',
                })
                console.log(JSON.stringify(err))
            })
            return undefined
        })
    }, [record.id, record.firstName, record.lastName, toaster, allUserProfiles])

    return (
        <>
            <Popover content={(
                <Menu>
                    {allUserProfiles?.map(userProfile => (
                        <MenuItem
                            text={`${userProfile.firstName} ${userProfile.lastName}`}
                            icon="person"
                            label={userProfile.id === record.AssignedToUserID ? 'selected' : ''}
                            onClick={() => handleAssignedToUserChanged(userProfile)}
                        />
                    ))}
                </Menu>
            )} position={Position.BOTTOM_RIGHT} minimal>
                <div className={`${styles.assignedToUserSelector} ${display === 'Nobody yet' && styles.noneSelected}`}>
                    {display}
                    <CaretDownFilled />
                </div>
            </Popover>
            <Alert
                intent='primary'
                icon="user"
                isOpen={alertIsOpen}
                onClose={handleAlertClose}
                onConfirm={handleAlertConfirm}
                confirmButtonText='Continue'
                cancelButtonText='Cancel'
            >
                <div style={{ fontSize: 18 }}>
                    <p>Are you sure that you want to change who this lead is assigned to?</p>
                    <p>We'll send an email to let them know you made this change.</p>
                </div>
            </Alert>
        </>
    )
}

const useStyles = createUseStyles({
    assignedToUserSelector: {
        display: 'grid',
        gridTemplateColumns: '1fr max-content',
        gap: 12,
        alignItems: 'center',
        backgroundColor: Colors.primary,
        borderRadius: 4,
        padding: '8px 12px',
        color: 'white',
        cursor: 'pointer',
        userSelect: 'none',
        border: `1px solid transparent`,
    },
    noneSelected: {
        backgroundColor: Colors.faint,
        color: Colors.defaultText,
        border: `1px solid ${Colors.subtext}`,
    },
})