import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { Colors } from '../../../../../../common/colors/colors'

type SideBarSectionProps = {
    label: string
}

export const SideBarSection: FC<SideBarSectionProps> = ({
    label,
    children,
}) => {

    // hooks
    const styles = useStyles()

    return (
        <div className={styles.sideBarSection}>
            <h3>{label}</h3>
            <div className={styles.menuItems}>
                {children}
            </div>
        </div>
    )
}

const useStyles = createUseStyles({
    sideBarSection: {
        display: 'grid',
        gridAutoRows: 'max-content',
        gap: 12,
        '&>h3': {
            textTransform: 'uppercase',
            margin: 0,
            color: Colors.subtext,
            fontSize: 14,
        },
    },
    menuItems: {
        display: 'grid',
        gridAutoRows: 'max-content',
        gap: 8,
    },
})