import { useEffect, useMemo } from "react";
import { useFirestoreDocument } from "../../common/firebase/firebase";
import { UserProfile } from "../../common/types/database";
import { RecordWithID } from "../../common/types/helpers";
import { convertRecordToRecordWithID } from "../../common/utils/convert-record-to-record-with-id";

export const useUserProfile = (userId?: string): RecordWithID<UserProfile> | undefined => {

    // hooks
    const reference = useMemo(() => userId ? `userProfiles/${userId}` : undefined, [userId])
    const [_a, setUserProfileDocumentPath, snapshot] = useFirestoreDocument<UserProfile>(reference)

    // side effects
    useEffect(() => {
        setUserProfileDocumentPath(reference)
    }, [reference])

    if (!snapshot) return

    return convertRecordToRecordWithID(snapshot)
    
}