import { RecordWithID } from "../types/helpers";
import firebase from "firebase";
import { DateTime } from "luxon";
import { DocumentMetadata } from "../types/database";

export const convertRecordToRecordWithID = <T extends DocumentMetadata>(snapshot: firebase.firestore.DocumentSnapshot<T>): RecordWithID<T> => {
    const data = snapshot.data() as T
    const createdDatetime = data._metadata.createdDatetime ? DateTime.fromMillis(data._metadata.createdDatetime.toMillis()) : undefined
    const updatedDatetime = data._metadata.updatedDatetime ? DateTime.fromMillis(data._metadata.updatedDatetime.toMillis()) : undefined

    return {
        id: snapshot.id,
        ...data,
        _metadata: { createdDatetime, updatedDatetime },
    }
}