import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { Outlet } from 'react-router'
import { Breakpoints } from '../../common/breakpoints/breakpoints'
import { Colors } from '../../common/colors/colors'
import { SideBar } from './subcomponents/side-bar/SideBar'
import { TopBar } from './subcomponents/top-bar/TopBar'

export const AppShell: FC = () => {

    // hooks
    const styles = useStyles()

    return (
        <div className={styles.container}>
            <TopBar />
            <SideBar />
            <main className={styles.content}>
                <Outlet />
            </main>
        </div>
    )
}

const useStyles = createUseStyles({
    container: {
        width: '100vw',
        height: '100vh',
        backgroundColor: Colors.siteBackground,
        display: 'grid',
        gridTemplateColumns: '300px 1fr',
        gridTemplateRows: '65px 1fr',
        maxHeight: '100vh',
        maxWidth: '100vw',
        overflow: 'hidden',
    },
    content: {
        display: 'grid',
    },
    [Breakpoints.mobile]: {
        container: {
            gridTemplateColumns: '1fr',
            gridTemplateRows: 'max-content 1fr',
        }
    },
})