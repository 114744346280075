import { AdvisorAccount, UserProfile } from "../types/database";
import firebase from 'firebase'
import * as xlsx from 'xlsx';
import { RecordWithID } from "../types/helpers";
import { convertRecordToRecordWithID } from "../utils/convert-record-to-record-with-id";
import { DateTime } from 'luxon';

export const updateAdvisorAccount = async (advisorAccountId: string, payload: Partial<AdvisorAccount>) => {

    const db = firebase.firestore()

    await db.doc(`advisorAccounts/${advisorAccountId}`).update(payload)

}

export const downloadLeads = async () => {

    const db = firebase.firestore()
    const leads: RecordWithID<AdvisorAccount>[] = (await db.collection('advisorAccounts').get()).docs.map(doc => convertRecordToRecordWithID(doc as any))
    const userProfiles = await db.collection('userProfiles').get()

    const userProfilesMap: { [key: string]: RecordWithID<UserProfile> } = {}
    for (const userProfileDoc of userProfiles.docs) {
        userProfilesMap[userProfileDoc.id] = convertRecordToRecordWithID(userProfileDoc as any)
    }

    const workbook = xlsx.utils.book_new()
    
    const sheetData: {
        "Name": string
        "Email Address": string
        "Phone Number": string
        "Access Code": string
        "Date Created": string
        "Assigned To": string
    }[] = leads.map(lead => ({
        "Name": `${lead.firstName} ${lead.lastName}`,
        "Email Address": lead.emailAddress,
        "Phone Number": lead.phoneNumber,
        "Access Code": lead.accessCode,
        "Date Created": lead._metadata.createdDatetime?.toLocaleString(DateTime.DATETIME_FULL) || "",
        "Assigned To": lead.AssignedToUserID && userProfilesMap[lead.AssignedToUserID] ? `${userProfilesMap[lead.AssignedToUserID].firstName} ${userProfilesMap[lead.AssignedToUserID].lastName}` : "",
    }))

    const sheet = xlsx.utils.json_to_sheet(sheetData)

    xlsx.utils.book_append_sheet(workbook, sheet, "Website Leads")

    xlsx.writeFile(workbook, `Website Leads ${DateTime.now().toLocaleString(DateTime.DATETIME_SHORT)}.xlsx`)
}