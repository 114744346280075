import { FC, useEffect, useState } from "react"
import firebase from 'firebase'
import { AuthenticationState, FirebaseContext } from "./firebase-context";
import { Spin } from "antd";
import { createUseStyles } from "react-jss";

const firebaseConfig = {
    apiKey: "AIzaSyDOKoh6BlAGPJmXhCnhrrYf_bnOc4Hy6oE",
    authDomain: "trg-fiduciary.firebaseapp.com",
    projectId: "trg-fiduciary",
    storageBucket: "trg-fiduciary.appspot.com",
    messagingSenderId: "716258856754",
    appId: "1:716258856754:web:1699aa1ab6917a4558d12b",
    measurementId: "G-779RCE21QE"
};

const useEmulators = false

export const FirebaseProvider: FC = ({
    children,
}) => {

    // state
    const styles = useStyles()
    const [hasInitialized, setHasInitialized] = useState<boolean>(false)
    const [user, setUser] = useState<firebase.User | null>()
    const [userId, setUserId] = useState<string>()
    const [authenticationState, setAuthenticationState] = useState<AuthenticationState>('loading')

    // side effects
    useEffect(() => {
        if (firebase.apps.length === 0) {
            firebase.initializeApp(firebaseConfig)
            if (useEmulators) {
                firebase.auth().useEmulator('http://localhost:9099')
                firebase.functions().useEmulator('localhost', 5001)
                firebase.firestore().useEmulator('localhost', 8080)
            }
            firebase.firestore().enablePersistence()
            const auth = firebase.auth()
            const authStateListener = auth.onAuthStateChanged(user => {
                setUser(user)
            })
            setHasInitialized(true)
            return () => {
                authStateListener()
            }
        }
    }, [])

    useEffect(() => {
        if (user === undefined) {
            setAuthenticationState('loading')
        } else if (user === null) {
            setAuthenticationState('notAuthenticated')
        } else {
            setAuthenticationState('authenticated')
        }
        setUserId(user?.uid)
    }, [user])

    return (
        <FirebaseContext.Provider value={{
            user,
            userId,
            authenticationState,
            onUserChange: setUser,
        }}>
            {hasInitialized ? (
                children
            ) : (
                <Spin>
                    <div className={styles.container} />
                </Spin>
            )}
        </FirebaseContext.Provider>
    )
}

const useStyles = createUseStyles({
    container: {
        width: '100vw',
        height: '100vh',
    },
})