import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { Breakpoints } from '../../../../common/breakpoints/breakpoints'
import { Shadows } from '../../../../common/colors/colors'
import { LeadIcon } from '../../../icons/lead-icon/LeadIcon'
import { NavigationButton } from './subcomponents/navigation-button/NavigationButton'
import { SideBarSection } from './subcomponents/side-bar-section/SideBarSection'

export const SideBar: FC = () => {

    // hooks
    const styles = useStyles()

    return (
        <div className={styles.sideBar}>
            <SideBarSection label='Main Navigation'>
                <NavigationButton
                    label='Website Leads'
                    icon={LeadIcon}
                    path='/website-leads'
                />
            </SideBarSection>
        </div>
    )
}

const useStyles = createUseStyles({
    sideBar: {
        display: 'grid',
        padding: 24,
        gridAutoRows: 'max-content',
        backgroundColor: 'white',
        boxShadow: Shadows.appShell,
    },
    [Breakpoints.mobile]: {
        sideBar: {
            display: 'none',
        },
    },
})