import { createContext } from "react";
import firebase from 'firebase'

export type AuthenticationState = 'loading' | 'authenticated' | 'notAuthenticated'

type FirebaseContext = {
    user: firebase.User | null | undefined
    userId?: string,
    authenticationState: AuthenticationState
    onUserChange: (user: firebase.User | null) => void
}

export const FirebaseContext = createContext<FirebaseContext>({
    user: null,
    authenticationState: 'loading',
    onUserChange: () => {},
})