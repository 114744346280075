import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { Breakpoints } from '../../../../common/breakpoints/breakpoints'
import { Colors, Shadows } from '../../../../common/colors/colors'
import { UserMenu } from '../../../user-menu/UserMenu'
import { NavigationMenu } from '../navigation-menu/NavigationMenu'

export const TopBar: FC = () => {

    // hooks
    const styles = useStyles()

    return (
        <div className={styles.topBar}>
            <img src="/images/logo/trg-logo.svg" />
            <h2>Marketing Dashboard</h2>
            <div className={styles.navigationMenuContainer}>
                <NavigationMenu />
            </div>
            <UserMenu />
        </div>
    )
}

const useStyles = createUseStyles({
    topBar: {
        display: 'grid',
        gridColumnStart: 1,
        gridColumnEnd: 3,
        backgroundColor: 'white',
        boxShadow: Shadows.appShell,
        zIndex: 1,
        paddingLeft: 24,
        paddingRight: 24,
        alignItems: 'center',
        gridAutoFlow: 'column',
        gridTemplateColumns: 'max-content 1fr max-content',
        gap: 20,
        color: Colors.defaultText,
    },
    navigationMenuContainer: {
        display: 'none',
    },
    [Breakpoints.mobile]: {
        topBar: {
            gridTemplateColumns: '1fr max-content',
            gridAutoFlow: 'row',
            gap: 8,
            paddingBottom: 24,
            paddingLeft: 18,
            paddingRight: 18,
            '&>img': {
                gridColumnStart: 2,
                gridColumnEnd: 3,
                gridRowStart: 1,
            },
            '&>h2': {
                gridColumnStart: 1,
                gridColumnEnd: 2,
                gridRowStart: 1,
            },
        },
        navigationMenuContainer: {
            display: 'grid',
        },
    },
})