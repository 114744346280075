import { IToaster, Toaster } from '@blueprintjs/core'
import React, { FC, useMemo, useRef, useState } from 'react'
import { ToasterContext } from './toaster-context'

export const ToasterProvider: FC = ({
    children,
}) => {

    // hooks
    const [toaster, setToaster] = useState<IToaster | undefined>()

    return (
        <ToasterContext.Provider value={{ toaster }}>
            <Toaster ref={ref => setToaster(ref ? ref : undefined)} />
            {children}
        </ToasterContext.Provider>
    )

}