import firebase from 'firebase'
import { isEqual } from 'lodash'
import { useEffect, useState } from 'react'
import {
    DocumentReferenceOrPath,
    FirebaseDocumentState,
    CollectionReferenceOrPath,
    FirebaseQueryState,
    FirebaseQueryData,
    FirebaseQueryFiltersAndSorts,
    FirebaseDocumentStates,
} from './firebase-types'

export const useFirestoreDocument = <T extends any>(initialDocumentReferenceOrPath?: DocumentReferenceOrPath<T>): FirebaseDocumentState<T> => {

    // state
    const [documentReferenceOrPath, setDocumentReferenceOrPath] = useState<DocumentReferenceOrPath<T> | undefined>(initialDocumentReferenceOrPath)
    const [documentReference, setDocumentReference] = useState<firebase.firestore.DocumentReference<T>>()
    const [snapshot, setSnapshot] = useState<firebase.firestore.DocumentSnapshot<T>>()
    const [data, setData] = useState<T>()
    const [snapshotListener, setSnapshotListener] = useState<(() => void)>()

    // side effects
    useEffect(() => {
        return () => {
            snapshotListener && snapshotListener()
        }
    }, [])

    useEffect(() => {
        if (documentReferenceOrPath) {
            const db = firebase.firestore()
            const newDocumentReference: firebase.firestore.DocumentReference<T> =
                typeof documentReferenceOrPath === 'string' ? db.doc(documentReferenceOrPath) as firebase.firestore.DocumentReference<T> : documentReferenceOrPath
            setDocumentReference(newDocumentReference)
        } else {
            setDocumentReference(undefined)
        }
    }, [documentReferenceOrPath])

    useEffect(() => {
        snapshotListener && snapshotListener()
        if (documentReference) {
            documentReference.get({
                source: 'cache'
            }).then(newSnapshot => {
                setSnapshot(newSnapshot)
                setData(newSnapshot.data())
            }).catch((reason: any) => {
                if (reason.toString().startsWith('FirebaseError: [code=unavailable]: Failed to get document from cache.')) {
                    documentReference.get().then(newSnapshot => {
                        setSnapshot(newSnapshot)
                        setData(newSnapshot.data())
                    })
                } else {
                    throw new Error(JSON.stringify({
                        error: 'An error occurred in useFirestoreDocument',
                        reason,
                        documentReferenceOrPath,
                    }, null, 2))
                }
            })
            const newSnapshotListener = () => documentReference.onSnapshot(newSnapshot => {
                try {
                    setSnapshot(newSnapshot)
                    setData(newSnapshot.data())
                } catch (reason) {
                    throw new Error(JSON.stringify({
                        error: 'An error occurred in a useFirestoreDocument snapshot listener',
                        reason,
                        documentReferenceOrPath,
                    }, null, 2))
                }
            })
            setSnapshotListener(newSnapshotListener)
        } else {
            setSnapshot(undefined)
            setData(undefined)
            setSnapshotListener(undefined)
        }
    }, [documentReference])

    return [data, setDocumentReferenceOrPath, snapshot]

}

export const useFirestoreQuery = <T extends any>(
    collectionReferenceOrPath: CollectionReferenceOrPath<T>,
    initialQuerySortsAndFilters?: FirebaseQueryFiltersAndSorts<T>,
): FirebaseQueryState<T> => {
    
    // state
    const [collectionReference] = useState<firebase.firestore.CollectionReference<T>>(() => {
        return typeof collectionReferenceOrPath === 'string' ? firebase.firestore().collection(collectionReferenceOrPath) as firebase.firestore.CollectionReference<T> : collectionReferenceOrPath
    })
    const [querySortsAndFilters, setQuerySortsAndFilters] = useState<FirebaseQueryFiltersAndSorts<T> | undefined>(initialQuerySortsAndFilters)
    const [cachedQuerySortsAndFilters, setCachedQueryFiltersAndSorts] = useState<FirebaseQueryFiltersAndSorts<T> | undefined>(initialQuerySortsAndFilters)
    const [queryReference, setQueryReference] = useState<firebase.firestore.Query<T>>()
    const [snapshots, setSnapshots] = useState<firebase.firestore.DocumentSnapshot<T>[]>()
    const [ids, setIds] = useState<string[]>()
    const [data, setData] = useState<{ [key: string]: T }>()
    const [snapshotListener, setSnapshotListener] = useState<(() => void)>()

    // side effects
    useEffect(() => {
        return () => {
            snapshotListener && snapshotListener()
        }
    }, [])

    useEffect(() => {
        const newCachedQuerySortsAndFilters = querySortsAndFilters ? { ...querySortsAndFilters } : undefined
        if (!isEqual(newCachedQuerySortsAndFilters, cachedQuerySortsAndFilters)) {
            setCachedQueryFiltersAndSorts(newCachedQuerySortsAndFilters)
        }
    }, [querySortsAndFilters])

    useEffect(() => {
        if (cachedQuerySortsAndFilters) {
            let newQueryReference = collectionReference as firebase.firestore.Query<T>
            cachedQuerySortsAndFilters.filters.forEach(([fieldPath, operator, value]) => newQueryReference = newQueryReference.where(fieldPath as string, operator, value))
            if (cachedQuerySortsAndFilters.sorts) {
                cachedQuerySortsAndFilters.sorts.forEach(([field, direction]) => newQueryReference = newQueryReference.orderBy(field as string, direction))
            }
            if (!isEqual(newQueryReference, queryReference)) {
                setQueryReference(newQueryReference)
            }
        } else {
            setQueryReference(undefined)
        }
    }, [cachedQuerySortsAndFilters])

    useEffect(() => {
        snapshotListener && snapshotListener()
        if (queryReference) {
            queryReference.get({
                // source: 'cache'
            }).then(querySnapshot => {
                setSnapshots(querySnapshot.docs)
                const newData: FirebaseQueryData<T> = {}
                const newIds: string[] = []
                querySnapshot.forEach(documentSnapshot => {
                    newData[documentSnapshot.id] = documentSnapshot.data()
                    newIds.push(documentSnapshot.id)
                })
                if (!isEqual(newData, data)) {
                    setData(newData)
                }
                if (!isEqual(ids ? [...ids].sort() : undefined, [...newIds].sort)) {
                    setIds(newIds)
                }
            }).catch((reason: any) => {
                if (reason.toString().startsWith('FirebaseError: [code=unavailable]: Failed to get document from cache.')) {
                    queryReference.get().then(querySnapshot => {
                        setSnapshots(querySnapshot.docs)
                        const newData: FirebaseQueryData<T> = {}
                        const newIds: string[] = []
                        querySnapshot.forEach(documentSnapshot => {
                            newData[documentSnapshot.id] = documentSnapshot.data()
                            newIds.push(documentSnapshot.id)
                        })
                        if (!isEqual(newData, data)) {
                            setData(newData)
                        }
                        if (!isEqual(ids ? [...ids].sort() : undefined, [...newIds].sort)) {
                            setIds(newIds)
                        }
                    })
                } else {
                    throw new Error(JSON.stringify({
                        error: 'An error occurred in useFirestoreQuery',
                        reason,
                        collectionReferenceOrPath,
                        querySortsAndFilters,
                    }, null, 2))
                }
            })
            const newSnapshotListener = () => queryReference.onSnapshot(querySnapshot => {
                try {
                    setSnapshots(querySnapshot.docs)
                    const newData: FirebaseQueryData<T> = {}
                    const newIds: string[] = []
                    querySnapshot.forEach(documentSnapshot => {
                        newData[documentSnapshot.id] = documentSnapshot.data()
                        newIds.push(documentSnapshot.id)
                    })
                    if (!isEqual(newData, data)) {
                        setData(newData)
                    }
                    if (!isEqual(ids ? [...ids].sort() : undefined, [...newIds].sort)) {
                        setIds(newIds)
                    }
                } catch (reason) {
                    throw new Error(JSON.stringify({
                        error: 'An error occurred in useFirestoreQuery',
                        reason,
                        collectionReferenceOrPath,
                        querySortsAndFilters,
                    }, null, 2))
                }
            })
            setSnapshotListener(newSnapshotListener)
        } else {
            setSnapshots(undefined)
            setData(undefined)
            setIds(undefined)
            setSnapshotListener(undefined)
        }
    }, [queryReference])
    
    return [ids, data, setCachedQueryFiltersAndSorts, snapshots]
}

export const useFirestoreDocuments = <T extends object>(collectionName: string, initialDocumentIds?: string[]): FirebaseDocumentStates<T> => {

    // state
    const [documentIds, setDocumentIds] = useState<string[] | undefined>(initialDocumentIds)
    const [documentReferences, setDocumentReferences] = useState<firebase.firestore.DocumentReference<T>[]>()
    const [snapshots, setSnapshots] = useState<firebase.firestore.DocumentSnapshot<T>[]>()
    const [data, setData] = useState<FirebaseQueryData<T>>()
    const [snapshotListeners, setSnapshotListeners] = useState<((() => void)[])>()

    // side effects
    useEffect(() => {
        return () => {
            snapshotListeners && snapshotListeners.forEach(snapshotListener => snapshotListener())
        }
    }, [])

    useEffect(() => {
        if (documentIds) {
            const db = firebase.firestore()
            const newDocumentReferences: firebase.firestore.DocumentReference<T>[] =
                documentIds.map(documentId => db.doc(`${collectionName}/${documentId}`)) as firebase.firestore.DocumentReference<T>[]
            setDocumentReferences(newDocumentReferences)
        } else {
            setDocumentReferences(undefined)
        }
    }, [documentIds])

    useEffect(() => {
        snapshotListeners && snapshotListeners.map(snapshotListener => snapshotListener())
        if (documentReferences) {
            Promise.all(documentReferences.map(async (documentReference): Promise<{ snapshot?: firebase.firestore.DocumentSnapshot<T>, snapshotListener?: () => void }> => {
                let snapshotListener: () => void 
                const snapshot = await documentReference.get()
                snapshotListener = () => documentReference.onSnapshot(newSnapshot => {
                    const newSnapshots: firebase.firestore.DocumentSnapshot<T>[] = snapshots ? [...snapshots].filter(snapshot => snapshot.id !== newSnapshot.id) : [newSnapshot]
                    newSnapshots.push(newSnapshot)
                    const newData: FirebaseQueryData<T> = data ? { ...data } : {}
                    newData[newSnapshot.id] = newSnapshot.data() as T
                    setSnapshots(newSnapshots)
                    setData(newData)
                })
                return { snapshot, snapshotListener }
            })).then(combinedData => {
                const newSnapshots = combinedData.map(d => d.snapshot).filter(d => d !== undefined) as firebase.firestore.DocumentSnapshot<T>[]
                const newData: FirebaseQueryData<T> = {}
                newSnapshots.forEach(newSnapshot => newData[newSnapshot.id] = newSnapshot.data() as T)
                const newSnapshotListeners = combinedData.map(d => d.snapshotListener).filter(d => d !== undefined) as (() => void)[]
                setSnapshots(newSnapshots)
                setData(newData)
                setSnapshotListeners(newSnapshotListeners)
            })
        } else {
            setSnapshots(undefined)
            setData(undefined)
            setSnapshotListeners(undefined)
        }
    }, [documentReferences])

    return [data, setDocumentIds, snapshots]

}