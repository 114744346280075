import { isEqual } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useFirestoreQuery } from "../../common/firebase/firebase";
import { FirebaseQueryFilters, FirebaseQueryFiltersAndSorts } from "../../common/firebase/firebase-types";
import { AdvisorAccount } from "../../common/types/database";
import { RecordWithID } from "../../common/types/helpers";
import { generateRecordsWithIds } from "../../common/utils/combine-ids-and-firebase-query-data";

export const useWebsiteLeads = (query?: FirebaseQueryFilters<AdvisorAccount>): RecordWithID<AdvisorAccount>[] | undefined => {

    // hooks
    const [filtersAndSorts, setFiltersAndSorts] = useState<FirebaseQueryFiltersAndSorts<AdvisorAccount> | undefined>(query ? { filters: query } : undefined)
    const [_a, _b, setQueryFilters, snapshots] = useFirestoreQuery<AdvisorAccount>('advisorAccounts', filtersAndSorts)
    const advisorAccounts = generateRecordsWithIds(snapshots)

    // side effects
    useEffect(() => {
        setFiltersAndSorts(oldFiltersAndSorts => {
            const newFiltersAndSorts: FirebaseQueryFiltersAndSorts<AdvisorAccount> | undefined = query ? { filters: query } : undefined
            if (!isEqual(oldFiltersAndSorts, newFiltersAndSorts)) {
                return newFiltersAndSorts
            }
            return oldFiltersAndSorts
        })
    }, [query])

    useEffect(() => {
        setQueryFilters(filtersAndSorts)
    }, [filtersAndSorts])

    return advisorAccounts

}