import { UserProfile } from "../../common/types/database";
import { RecordWithID } from "../../common/types/helpers";
import { useUserProfiles } from "../documents/use-user-profiles";

export const useAllUserProfiles = (): RecordWithID<UserProfile>[] | undefined => {

    // hooks
    const allUserProfiles = useUserProfiles([])

    return allUserProfiles

}