import { FC, useCallback, useMemo, useState } from 'react'
import { ShowingMenuButton } from '../view-title/subcomponents/ShowingMenuButton'
import { ViewTitle } from '../view-title/ViewTitle'
import { Popover2 as Popover } from "@blueprintjs/popover2";
import { Button, IconName, Menu, MenuItem, Position } from '@blueprintjs/core';
import { WebsiteLeadsTable } from '../website-leads-table/WebsiteLeadsTable';
import { createUseStyles } from 'react-jss';
import { useWebsiteLeads } from '../../hooks/documents/use-website-leads';
import { FirebaseQueryFilters } from '../../common/firebase/firebase-types';
import { AdvisorAccount } from '../../common/types/database';
import { Breakpoints } from '../../common/breakpoints/breakpoints';
import { WebsiteLeadsList } from '../website-leads-list/WebsiteLeadsList';
import { DownloadtoExcelButton } from './subcomponents/download-to-excel-button/DownloadtoExcelButton';

const filtersMap: { [key: string]: FirebaseQueryFilters<AdvisorAccount> } = {
    allLeads: [],
    onlyAssigned: [['AssignedToUserID', '!=', null]],
    notAssigned: [['AssignedToUserID', '==', null]],
}

type MenuOption = {
    icon: IconName
    text: string
    id: string
}

export const WebsiteLeadsView: FC = () => {

    // hooks
    const styles = useStyles()
    const [filtersKey, setFiltersKey] = useState<string>('allLeads')
    const query = useMemo(() => filtersMap[filtersKey], [filtersKey])
    const websiteLeads = useWebsiteLeads(query)
    const menuOptions = useMemo(() => {
        const menuOptions: MenuOption[] = [
            { icon: 'person', text: 'All Leads', id: 'allLeads' },
            { icon: 'endorsed', text: 'Only Assigned', id: 'onlyAssigned' },
            { icon: 'graph-remove', text: 'Only Not Assigned', id: 'notAssigned' },
        ]
        return menuOptions
    }, [])
    const selectedKeyLabel = useMemo(() => {
        const menuOption = menuOptions.find(menuOption => menuOption.id === filtersKey)
        if (!menuOption) return 'Loading...'
        return menuOption.text
    }, [filtersKey, menuOptions])

    // event handlers
    const handleFilterChange = useCallback((key:string) => {
        setFiltersKey(key)
    }, [])

    return (
        <div className={styles.websiteLeadsView}>
            <ViewTitle
                label='Website Leads'
                showing={(
                    <Popover content={(
                        <Menu>
                            {menuOptions.map(menuOption => (
                                <MenuItem 
                                    key={menuOption.id}
                                    icon={menuOption.icon}
                                    text={menuOption.text}
                                    label={filtersKey === menuOption.id ? 'showing' : ''}
                                    onClick={() => handleFilterChange(menuOption.id)}
                                />
                            ))}
                        </Menu>
                    )} position={Position.BOTTOM_LEFT} minimal>
                        <ShowingMenuButton label={selectedKeyLabel} />
                    </Popover>
                )}
                actions={(
                    <DownloadtoExcelButton />
                )}
            />
            <div className={styles.leadsTableContainer}>
                <WebsiteLeadsTable websiteLeads={websiteLeads} />
            </div>
            <div className={styles.leadsListContainer}>
                <WebsiteLeadsList websiteLeads={websiteLeads} />
            </div>
        </div>
    )
}

const useStyles = createUseStyles({
    websiteLeadsView: {
        display: 'grid',
        gridTemplateRows: '70px calc(100vh - 180px)',
        gap: 20,
    },
    leadsTableContainer: {
        display: 'grid',
    },
    leadsListContainer: {
        display: 'none',
    },
    [Breakpoints.mobile]: {
        leadsTableContainer: {
            display: 'none',
        },
        leadsListContainer: {
            display: 'grid',
        },
    },
})