import { useContext } from "react";
import { UserProfile } from "../../common/types/database";
import { RecordWithID } from "../../common/types/helpers";
import { FirebaseContext } from "../../components/firebase-provider/firebase-context";
import { useUserProfile } from "../documents/use-user-profile";

export const useMyUserProfile = (): RecordWithID<UserProfile> | undefined => {

    // hooks
    const { userId } = useContext(FirebaseContext)
    const userProfile = useUserProfile(userId)

    return userProfile

}