import { FirebaseQueryData } from "../firebase/firebase-types"
import { RecordWithID } from "../types/helpers"
import firebase from 'firebase'
import { convertRecordToRecordWithID } from "./convert-record-to-record-with-id"
import { DocumentMetadata } from "../types/database"

export const generateRecordsWithIds = <RecordType extends DocumentMetadata>(snapshots?: firebase.firestore.DocumentSnapshot<RecordType>[]): RecordWithID<RecordType>[] | undefined => {

    if (!snapshots) return

    const records: RecordWithID<RecordType>[] = snapshots.map(convertRecordToRecordWithID)

    return records

}