import React, { FC, ReactNode } from 'react'
import { createUseStyles } from 'react-jss'
import { Colors } from '../../common/colors/colors'

type ViewTitleProps = {
    label: string
    showing?: ReactNode
    actions?: ReactNode
}

export const ViewTitle: FC<ViewTitleProps> = ({
    label,
    showing,
    actions,
}) => {

    // hooks
    const styles = useStyles()

    return (
        <div className={styles.viewTitle}>
            <div className={styles.topRow}>
                <h2>{label}</h2>
                <div className={styles.actions}>
                    {actions}
                </div>
            </div>
            {showing && (
                <div className={styles.showingContainer}>
                    <span className={styles.showingLabel}>Showing</span>
                    {showing}
                </div>
            )}
        </div>
    )
}

const useStyles = createUseStyles({
    viewTitle: {
        display: 'grid',
        gridAutoRows: 'max-content',
        gridTemplateColumns: '100%',
        gap: 8,
        '& h2': {
            fontSize: 24,
            fontWeight: 600,
            color: Colors.defaultText,
            margin: 0,
        },
    },
    showingContainer: {
        display: 'grid',
        gridAutoFlow: 'column',
        gridAutoColumns: 'max-content',
        gap: 6,
    },
    showingLabel: {
        fontSize: 16,
        color: Colors.subtext,
    },
    topRow: {
        display: "grid",
        gridTemplateColumns: "1fr max-content",
        alignItems: 'center',
    },
    actions: {
        display: "flex",
        flexDirection: "row",
        gap: 8,
        alignItems: "center",
    }
})