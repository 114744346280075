import React, { FC, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import { useMyUserProfile } from '../../hooks/contextual/use-my-user-profile'
import { CaretDownFilled } from "@ant-design/icons";
import { Popover2 as Popover } from "@blueprintjs/popover2";
import { Menu, MenuItem, Position } from '@blueprintjs/core';
import { signOut } from '../../common/logic/authentication-logic';
import { Breakpoints } from '../../common/breakpoints/breakpoints';
import { UserCircleIcon } from '../icons/user-cirlce-icon/UserCircleIcon';
import { Colors } from '../../common/colors/colors';

export const UserMenu: FC = () => {

    // hooks
    const styles = useStyles()
    const myUserProfile = useMyUserProfile()

    // event handlers
    const handleSignOutClick = useCallback(() => {
        signOut()
    }, [])

    return (
        <Popover content={(
            <Menu>
                <MenuItem text="Sign Out" intent='danger' icon='log-out' onClick={handleSignOutClick} />
            </Menu>
        )} position={Position.BOTTOM_RIGHT} minimal>
            <div className={styles.userMenu}>
                <span className={styles.userName}>
                    {myUserProfile?.firstName} {myUserProfile?.lastName}
                </span>
                <div className={styles.userIcon}>
                    <UserCircleIcon size={24} color={Colors.defaultText} />
                </div>
                <CaretDownFilled />
            </div>
        </Popover>
    )
}

const useStyles = createUseStyles({
    userMenu: {
        display: 'grid',
        fontSize: 18,
        gridAutoFlow: 'column',
        alignItems: 'center',
        gap: 6,
        cursor: 'pointer',
    },
    userName: {
        fontWeight: 500,
    },
    userIcon: {
        display: 'none',
    },
    [Breakpoints.mobile]: {
        userMenu: {
            justifyContent: 'right',
        },
        userName: {
            maxWidth: 50,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        userIcon: {
            display: 'block',
        },
    },
})