import { Button } from '@blueprintjs/core'
import React, { FC, useCallback, useState } from 'react'
import { downloadLeads } from '../../../../common/logic/advisor-account-logic'

type DownloadtoExcelButtonProps = {}

export const DownloadtoExcelButton: FC<DownloadtoExcelButtonProps> = () => {
	
	// state
	const [isLoading, setIsLoading] = useState<boolean>(false)

	// event handlers
	const handleClick = useCallback(async () => {
		setIsLoading(true)
		await downloadLeads()
		setIsLoading(false)
	}, [])

	return (
		<Button
			large
			icon="download"
			loading={isLoading}
			onClick={handleClick}
		>
			Export to Excel
		</Button>
	)
}