import React, { ComponentType, FC } from 'react'
import { createUseStyles } from 'react-jss'
import { Link, useMatch } from 'react-router-dom'
import { Colors } from '../../../../../../common/colors/colors'
import { IconProps } from '../../../../../icons/icon-props'

type NavigationButtonProps = {
    label: string
    icon: ComponentType<IconProps>
    path: string
}

export const NavigationButton: FC<NavigationButtonProps> = ({
    label,
    icon: Icon,
    path,
}) => {


    // hooks
    const styles = useStyles()
    const active = useMatch(path)

    return (
        <Link to={path}>
            <div className={`${styles.navigationButton} ${active && styles.active}`}>
                <Icon size={20} color={active ? Colors.primary : Colors.subtext} />
                <span className={styles.label}>{label}</span>
            </div>
        </Link>
    )
}

const useStyles = createUseStyles({
    navigationButton: {
        display: 'grid',
        gridAutoFlow: 'column',
        color: Colors.subtext,
        alignItems: 'center',
        gridTemplateColumns: 'max-content 1fr',
        gap: 10,
        padding: '10px 14px',
        borderRadius: 4,
        '&:hover': {
            backgroundColor: '#F6F7F9',
        },
        '&:active': {
            backgroundColor: '#ECEEF4',
        },
    },
    label: {
        fontSize: 18,
        fontWeight: 600,
        textDecoration: 'none !important'
    },
    active: {
        color: Colors.primary,
        backgroundColor: '#F6F7F9',
        boxShadow: 'inset 0px 1px 6px #00000014',
    }
})