import React, { useContext, useMemo } from 'react';
import logo from './logo.svg';
import './App.css';
import { Routes, Route, Navigate } from 'react-router';
import { Authenticate } from './components/authenticate/Authenticate';
import { FirebaseContext } from './components/firebase-provider/firebase-context';
import { AppShell } from './components/app-shell/AppShell';
import { WebsiteLeadsRoute } from './components/website-leads-route/WebsiteLeadsRoute';

function App() {

  // hooks
  const { user } = useContext(FirebaseContext)
  const needsAuthentication = useMemo(() => user === null ? true : false, [user])

  return (
    <Routes>
      {needsAuthentication ? (
        <Route path="*" element={<Authenticate />} />
      ) : (
        <>
          <Route path="*" element={<AppShell />}>
            <Route path="" element={<Navigate replace to="/website-leads" />} />
            <Route path="website-leads" element={<WebsiteLeadsRoute />} />
          </Route>
        </>
      )}
    </Routes>
  );
}

export default App;
