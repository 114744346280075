import { CaretDownFilled } from '@ant-design/icons'
import { IconName, Menu, MenuItem, Position } from '@blueprintjs/core'
import React, { ComponentType, FC, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useLocation } from 'react-router'
import { Colors } from '../../../../common/colors/colors'
import { IconProps } from '../../../icons/icon-props'
import { LeadIcon } from '../../../icons/lead-icon/LeadIcon'
import { Popover2 as Popover } from "@blueprintjs/popover2";

type MenuOption = {
    icon: ComponentType<IconProps>
    text: string
    path: string
}

export const NavigationMenu: FC = () => {

    // hooks
    const styles = useStyles()
    const location = useLocation()
    const menuOptions = useMemo(() => {
        const menuOptions: MenuOption[] = [
            { icon: LeadIcon, text: 'Website Leads', path: '/website-leads' },
        ]
        return menuOptions
    }, [])
    const selectedOption = useMemo(() => {
        const menuOption = menuOptions.find(menuOption => location.pathname.startsWith(menuOption.path))
        if (!menuOption) return
        return menuOption
    }, [location.pathname, menuOptions])
    const Icon = selectedOption?.icon

    return (
        <Popover content={(
            <Menu>
                {menuOptions.map(menuOption => {
                    const Icon = selectedOption?.icon
                    return <MenuItem key={menuOption.path} text={menuOption.text} icon={Icon && <Icon size={18} color={Colors.defaultText} />} />
                })}
            </Menu>
        )} position={Position.BOTTOM_LEFT} minimal>
            <div className={styles.navigationMenu}>
                {Icon ? <Icon size={18} color={Colors.defaultText} /> : <div />}
                {selectedOption?.text}
                <CaretDownFilled />
            </div>
        </Popover>
    )
}

const useStyles = createUseStyles({
    navigationMenu: {
        display: 'grid',
        gridTemplateColumns: 'max-content 1fr max-content',
        gap: 8,
        alignItems: 'center',
        border: `1px solid ${Colors.defaultText}`,
        borderRadius: 6,
        padding: 12,
        color: Colors.defaultText,
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
        '&:hover': {
            backgroundColor: Colors.faint,
        },
    },
})