export const Colors = {
    siteBackground: '#F8F8F8',
    defaultText: '#33425E',
    subtext: '#707070',
    primary: '#2e6cc6',
    warn: '#d88e18',
    border: {
        light: '#efefef',
        default: '#dbdbdb',
    },
    faint: '#FAFBFF',
}

export const Shadows = {
    default: '0px 3px 6px #00000029',
    appShell: '0px 3px 9px #0000000F',
}