import { createContext, Dispatch, SetStateAction } from "react";
import { UserProfile } from "../../common/types/database";
import { RecordWithID } from "../../common/types/helpers";

type WebsiteLeadsTableContext = {
    allUserProfiles: RecordWithID<UserProfile>[] | undefined
}

export const WebsiteLeadsTableContext = createContext<WebsiteLeadsTableContext>({
    allUserProfiles: undefined,
})