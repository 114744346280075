import React, { FC, useMemo } from 'react'
import { AdvisorAccount } from '../../common/types/database'
import { RecordWithID } from '../../common/types/helpers'
import { Column, Row, useTable } from "react-table";
import { createUseStyles } from 'react-jss';
import { Colors, Shadows } from '../../common/colors/colors';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import PhoneNumber from "awesome-phonenumber";
import { Tag } from '../tag/Tag';
import { AssignedToUserSelector } from './assigned-to-user-selector/AssignedToUserSelector';
import { WebsiteLeadsTableContext } from './website-leads-table-context';
import { useAllUserProfiles } from '../../hooks/contextual/use-all-user-profiles';

type WebsiteLeadsTableProps = {
    websiteLeads: RecordWithID<AdvisorAccount>[] | undefined
}

export const WebsiteLeadsTable: FC<WebsiteLeadsTableProps> = ({
    websiteLeads,
}) => {

    // hooks
    const styles = useStyles()
    const allUserProfiles = useAllUserProfiles()
    const sortedUserProfiles = allUserProfiles?.sort((a, b) => {
        const aName = `${a.firstName} ${a.lastName}`
        const bName = `${b.firstName} ${b.lastName}`
        if (aName > bName) return -1
        if (bName > aName) return 1
        return 0
    })
    const columns: Column<RecordWithID<AdvisorAccount>>[] = useMemo(() => ([
        { Header: 'Name', accessor: record => `${record.firstName} ${record.lastName}` },
        { Header: 'Email Address', accessor: 'emailAddress', Cell: (props: { value: string }) => (<a href={`mailto:${props.value}`}>{props.value}</a>) },
        { Header: 'Phone Number', accessor: 'phoneNumber', Cell: (props: { value: string }) => {
            return <a href={`tel:${new PhoneNumber(props.value, 'US').getNumber()}`}>{props.value}</a>
        } },
        { Header: 'Access Code', accessor: 'accessCode' },
        { Header: 'Date Created', accessor: record => record._metadata.createdDatetime?.toLocaleString(DateTime.DATETIME_FULL) },
        { Header: 'Assigned To', accessor: 'AssignedToUserID', Cell: (props: { row: Row<RecordWithID<AdvisorAccount>> }) => <AssignedToUserSelector record={props.row.original} /> }
    ]), [])
    const data = useMemo(() => websiteLeads ? websiteLeads : [], [websiteLeads])
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data })

    return (
        <WebsiteLeadsTableContext.Provider value={{ allUserProfiles: sortedUserProfiles }}>
            <div className={styles.websiteLeadsTable}>
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()}>
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map(row => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return (
                                            <td {...cell.getCellProps()}>
                                                {cell.render('Cell')}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </WebsiteLeadsTableContext.Provider>
    )
}

const useStyles = createUseStyles({
    websiteLeadsTable: {
        backgroundColor: 'white',
        borderRadius: 8,
        maxWidth: '100%',
        overflow: 'auto',
        boxShadow: Shadows.appShell,
        '& table': {
            borderCollapse: 'collapse',
            width: '100%',
            whiteSpace: 'nowrap',
            '& thead': {
                position: 'sticky',
                top: 0,
                '& tr': {
                    borderBottom: `1px solid ${Colors.border.default}`,
                    backgroundColor: Colors.faint,
                    '& th': {
                        borderBottom: `1px solid ${Colors.border.default}`,
                        textAlign: 'left',
                        padding: 18,
                        fontSize: 16,
                        fontWeight: 500,
                        color: Colors.defaultText,
                    },
                },
            },
            '& tbody': {
                '& tr': {
                    borderBottom: `1px solid ${Colors.border.light}`,
                    '& td': {
                        padding: '8px 18px',
                        fontSize: 16,
                        color: Colors.defaultText,
                    },
                },
            },
        },
    },
})