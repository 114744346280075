import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { Breakpoints } from '../../common/breakpoints/breakpoints'

export const Content: FC = ({
    children,
}) => {

    // hooks
    const styles = useStyles()

    return (
        <div className={styles.content}>
            {children}
        </div>
    )
}

const useStyles = createUseStyles({
    content: {
        display: 'grid',
        padding: 24,
    },
    [Breakpoints.mobile]: {
        content: {
            padding: 18,
        },
    },
})