import { FormGroup, InputGroup, Button } from '@blueprintjs/core';
import React, { FC, useCallback, useContext } from 'react'
import { useForm, Controller } from 'react-hook-form';
import { createUseStyles } from "react-jss";
import { Breakpoints } from '../../common/breakpoints/breakpoints';
import { Colors, Shadows } from '../../common/colors/colors';
import { signIn } from '../../common/logic/authentication-logic';
import { ToasterContext } from '../toaster-provider/toaster-context';

type FormData = {
    emailAddress: string
    password: string
}

export const Authenticate: FC = () => {

    // hooks
    const styles = useStyles()
    const { toaster } = useContext(ToasterContext)
    const { control, formState, handleSubmit } = useForm<FormData>({
        mode: 'onChange', defaultValues: {
            emailAddress: '',
            password: '',
        }
    })

    // event handlers
    const handleFormSubmit = useCallback((data: FormData) => {
        const { emailAddress, password } = data
        signIn(emailAddress, password).catch(err => {
            toaster?.show({
                message: err.message,
                intent: 'danger',
            })
            console.log(err)
        })
    }, [])

    return (
        <div className={styles.container}>
            <div className={styles.centerConatiner}>
                <img src="/images/logo/trg-logo.svg" style={{ height: 36, }} />
                <h1 style={{ margin: 0, marginBottom: 22, color: Colors.defaultText }} >Marketing Dashboard</h1>
                <div className={styles.authenticateCard}>
                    <h1>Please Sign In</h1>
                    <form onSubmit={handleSubmit(handleFormSubmit)}>
                        <FormGroup
                            label="Email Address"
                            labelFor="text-input"
                        >
                            <Controller
                                name="emailAddress"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <InputGroup
                                        id="email-address"
                                        placeholder="email@example.com"
                                        type="username"
                                        autoFocus
                                        large
                                        {...field}
                                    />
                                )}
                            />
                        </FormGroup>
                        <FormGroup
                            label="Password"
                            labelFor="password"
                        >
                            <Controller
                                name="password"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <InputGroup
                                        id="email-address"
                                        placeholder="•••••••••••••"
                                        type="password"
                                        large
                                        {...field}
                                    />
                                )}
                            />
                        </FormGroup>
                        <div className={styles.footer}>
                            <Button intent="primary" type="submit" large disabled={!formState.isValid}>Sign In</Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

const useStyles = createUseStyles({
    container: {
        display: 'grid',
        width: '100vw',
        height: '100vh',
        backgroundColor: Colors.siteBackground,
        justifyContent: 'center',
        gridAutoRows: 'max-content',
        paddingTop: '20vh',
    },
    centerConatiner: {
        display: 'grid',
        gridAutoRows: 'max-content',
        gap: 22,
        width: 500,
        maxWidth: 'calc(100vw - 24px)',
        justifyItems: 'center',
    },
    authenticateCard: {
        boxShadow: Shadows.default,
        backgroundColor: 'white',
        borderRadius: 8,
        padding: 24,
        paddingTop: 12,
        display: 'grid',
        gridAutoFlow: 'row',
        gridAutoRows: 'max-content',
        gap: 8,
        width: '100%',
    },
    footer: {
        display: 'grid',
        justifyContent: 'end',
    },
    [Breakpoints.mobile]: {
        container: {
            paddingTop: 100,
        },
        centerConatiner: {
            paddingLeft: 24,
            paddingRight: 24,
        },
        authenticateCard: {
            marginTop: 12,
            gap: 12,
            '&>h1': {
                fontSize: 24,
            },
        },
    },
})