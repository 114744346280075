import React, { FC } from 'react'
import { IconProps } from '../icon-props'

export const UserCircleIcon: FC<IconProps> = ({
    size,
    color,
}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 496 496" fill={color}>
            <path id="Path_45" data-name="Path 45" d="M248,8C111,8,0,119,0,256S111,504,248,504,496,393,496,256,385,8,248,8Zm0,96a88,88,0,1,1-88,88A88,88,0,0,1,248,104Zm0,344a191.627,191.627,0,0,1-146.5-68.2C120.3,344.4,157.1,320,200,320a24.468,24.468,0,0,1,7.1,1.1A132.4,132.4,0,0,0,248,328a131.9,131.9,0,0,0,40.9-6.9A24.468,24.468,0,0,1,296,320c42.9,0,79.7,24.4,98.5,59.8A191.627,191.627,0,0,1,248,448Z" transform="translate(0 -8)" />
        </svg>
    )
}