import firebase from 'firebase'

export const checkIfUser = async (email: string): Promise<boolean> => {
    const auth = firebase.auth()
    const signInMethods = await auth.fetchSignInMethodsForEmail(email)
    if (signInMethods.length > 0) {
        return true
    }
    return false
}

export const createAccount = async (email: string, password: string, name: string) => {
    const auth = firebase.auth()
    debugger
    const response = await auth.createUserWithEmailAndPassword(email, password)
    if (response.user) {
        const db = firebase.firestore()
        return db.doc(`userProfiles/${response.user?.uid}`).set({
            name,
            email,
        })
    }
}

export const signIn = async (email: string, password: string) => {
    const auth = firebase.auth()
    return auth.signInWithEmailAndPassword(email, password)
}

export const signOut = async () => {
    const auth = firebase.auth()
    auth.signOut()
}

export const sendPasswordResetLink = async (email: string) => {
    const auth = firebase.auth()
    return auth.sendPasswordResetEmail(email)
}