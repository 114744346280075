import { CaretDownFilled } from '@ant-design/icons'
import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { Colors } from '../../../common/colors/colors'

type ShowingMenuButtonProps = {
    label: string
}

export const ShowingMenuButton: FC<ShowingMenuButtonProps> = ({
    label,
}) => {

    // hooks
    const styles = useStyles()

    return (
        <div className={styles.showingMenuButton}>
        <span className={styles.label}>
            {label}
        </span>
        <CaretDownFilled />
    </div>
    )
}

const useStyles = createUseStyles({
    showingMenuButton: {
        display: 'grid',
        fontSize: 16,
        gridAutoFlow: 'column',
        alignItems: 'center',
        gap: 2,
        cursor: 'pointer',
        color: Colors.defaultText,
    },
    label: {
        fontWeight: 600,
        paddingBottom: 1,
    },
})