import { isEqual } from "lodash";
import { useEffect, useState } from "react";
import { useFirestoreQuery } from "../../common/firebase/firebase";
import { FirebaseQueryFilters, FirebaseQueryFiltersAndSorts } from "../../common/firebase/firebase-types";
import { UserProfile } from "../../common/types/database";
import { RecordWithID } from "../../common/types/helpers";
import { generateRecordsWithIds } from "../../common/utils/combine-ids-and-firebase-query-data";

export const useUserProfiles = (query?: FirebaseQueryFilters<UserProfile>): RecordWithID<UserProfile>[] | undefined => {
    
    // hooks
    const [filtersAndSorts, setFiltersAndSorts] = useState<FirebaseQueryFiltersAndSorts<UserProfile> | undefined>(query ? { filters: query } : undefined)
    const [_a, _b, setQueryFilters, snapshots] = useFirestoreQuery<UserProfile>('userProfiles', filtersAndSorts)
    const userProfiles = generateRecordsWithIds(snapshots)

    // side effects
    useEffect(() => {
        setFiltersAndSorts(oldFiltersAndSorts => {
            const newFiltersAndSorts: FirebaseQueryFiltersAndSorts<UserProfile> | undefined = query ? { filters: query } : undefined
            if (!isEqual(oldFiltersAndSorts, newFiltersAndSorts)) {
                return newFiltersAndSorts
            }
            return oldFiltersAndSorts
        })
    }, [query])

    useEffect(() => {
        setQueryFilters(filtersAndSorts)
    }, [filtersAndSorts])

    return userProfiles

}