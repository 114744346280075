import React, { FC } from 'react'
import { Content } from '../content/Content'
import { WebsiteLeadsView } from '../website-leads-view/WebsiteLeadsView'

export const WebsiteLeadsRoute: FC = () => {
    return (
        <Content>
            <WebsiteLeadsView />
        </Content>
    )
}