import PhoneNumber from 'awesome-phonenumber'
import { DateTime } from 'luxon'
import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { Colors, Shadows } from '../../common/colors/colors'
import { AdvisorAccount } from '../../common/types/database'
import { RecordWithID } from '../../common/types/helpers'
import { useAllUserProfiles } from '../../hooks/contextual/use-all-user-profiles'
import { AssignedToUserSelector } from '../website-leads-table/assigned-to-user-selector/AssignedToUserSelector'
import { WebsiteLeadsTableContext } from '../website-leads-table/website-leads-table-context'

type WebsiteLeadsListProps = {
    websiteLeads: RecordWithID<AdvisorAccount>[] | undefined
}

export const WebsiteLeadsList: FC<WebsiteLeadsListProps> = ({
    websiteLeads,
}) => {

    // hooks
    const styles = useStyles()
    const allUserProfiles = useAllUserProfiles()
    const sortedUserProfiles = allUserProfiles?.sort((a, b) => (
        `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`)
    ))

    return (
        <WebsiteLeadsTableContext.Provider value={{ allUserProfiles: sortedUserProfiles }}>
            <div className={styles.websiteLeadsList}>
                {websiteLeads?.map(lead => (
                    <div key={lead.id} className={styles.listItem}>
                        <div className={styles.nameContainer}>
                            <h3>{lead.firstName}&nbsp;{lead.lastName}</h3>
                        </div>
                        <div className={styles.phoneContainer}>
                            <a href={`tel:${new PhoneNumber(lead.phoneNumber, 'US').getNumber()}`}>{lead.phoneNumber}</a>
                        </div>
                        <div className={styles.emailContainer}>
                            <a href={`mailto:${lead.emailAddress}`}>{lead.emailAddress}</a>
                        </div>
                        <div className={styles.selectorContainer}>
                            <AssignedToUserSelector record={lead} />
                        </div>
                        <div className={styles.dateContainer}>
                            {lead._metadata.createdDatetime?.toLocaleString(DateTime.DATETIME_SHORT)}
                        </div>
                    </div>
                ))}
            </div>
        </WebsiteLeadsTableContext.Provider>
    )
}

const useStyles = createUseStyles({
    websiteLeadsList: {
        backgroundColor: 'white',
        borderRadius: 8,
        overflowY: 'auto',
        boxShadow: Shadows.appShell,
    },
    listItem: {
        display: 'grid',
        gridTemplateColumns: '1fr max-content',
        gridTemplateRows: 'repeat(3, max-content)',
        padding: 18,
        borderBottom: `1px solid ${Colors.border.light}`,
        gap: 2,
        fontSize: 16,
    },
    nameContainer: {
        display: 'grid',
        gridColumnStart: 1,
        gridColumnEnd: 2,
        gridRowStart: 1,
        gridRowEnd: 2,
        '& h3': {
            margin: 0,
            fontSize: 20,
        },
    },
    phoneContainer: {
        display: 'grid',
        gridColumnStart: 1,
        gridColumnEnd: 2,
        gridRowStart: 2,
        gridRowEnd: 3,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    emailContainer: {
        display: 'grid',
        gridColumnStart: 1,
        gridColumnEnd: 2,
        gridRowStart: 3,
        gridRowEnd: 4,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%'
    },
    selectorContainer: {
        display: 'grid',
        gridColumnStart: 2,
        gridColumnEnd: 3,
        gridRowStart: 1,
        gridRowEnd: 2,
    },
    dateContainer: {
        display: 'grid',
        gridColumnStart: 2,
        gridColumnEnd: 3,
        gridRowStart: 3,
        gridRowEnd: 4,
    }
})