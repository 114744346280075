import React, { FC } from 'react'
import { IconProps } from '../icon-props'

export const LeadIcon: FC<IconProps> = ({
    color,
    size,
}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 18 20.571" fill={color}>
            <path id="Path_27" data-name="Path 27" d="M9,10.286A5.143,5.143,0,1,0,3.857,5.143,5.142,5.142,0,0,0,9,10.286Zm3.849,1.31-1.921,7.69L9.643,13.821l1.286-2.25H7.071l1.286,2.25L7.071,19.286,5.151,11.6A5.391,5.391,0,0,0,0,16.971v1.671a1.929,1.929,0,0,0,1.929,1.929H16.071A1.929,1.929,0,0,0,18,18.643V16.971A5.391,5.391,0,0,0,12.849,11.6Z" />
        </svg>
    )
}